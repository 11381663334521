import React from 'react';
import './Loader.css';

const Loader = ({ showLoader=false }) => {
  return (
    <>
    {
      showLoader ? (
        <div className="common-loader">
          <span></span>
        </div>
      ) :
      null
    }
    </>
  )
}

export default Loader;
