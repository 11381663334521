// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../Assets/Images/loader1.gif", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.common-loader {
    background: rgba(255, 255, 255, 0.2);
    position: fixed;
    width: 100%;
    left: 0;
    height: 100%;
    top: 0;
    z-index: 9999;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.common-loader span {
    background: no-repeat url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    display: inline-block;
    width: 200px;
    height: 200px;
    background-size: cover;
}
.container-fluid.loading {
    background-color: #fff; /* Set a white background color */
  }`, "",{"version":3,"sources":["webpack://./src/Components/Common/Loader.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC;IACpC,eAAe;IACf,WAAW;IACX,OAAO;IACP,YAAY;IACZ,MAAM;IACN,aAAa;IACb,kBAAkB;IAClB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,6DAA4D;IAC5D,qBAAqB;IACrB,YAAY;IACZ,aAAa;IACb,sBAAsB;AAC1B;AACA;IACI,sBAAsB,EAAE,iCAAiC;EAC3D","sourcesContent":[".common-loader {\n    background: rgba(255, 255, 255, 0.2);\n    position: fixed;\n    width: 100%;\n    left: 0;\n    height: 100%;\n    top: 0;\n    z-index: 9999;\n    text-align: center;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.common-loader span {\n    background: no-repeat url('../../Assets/Images/loader1.gif');\n    display: inline-block;\n    width: 200px;\n    height: 200px;\n    background-size: cover;\n}\n.container-fluid.loading {\n    background-color: #fff; /* Set a white background color */\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
