import React, { useEffect } from "react";
import Heading from "./Heading";
import Cards from "./Cards";
import FooterPage from "./FooterPage";

function Dashboard() {

  useEffect(() => {
    document.title = process.env.REACT_APP_APP_TITLE;
  }, []);

  return (
    <>
      <Heading />
      <Cards />
      <FooterPage />
    </>
  );
}

export default Dashboard;
