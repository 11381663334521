import axios from "axios";
import React, { useEffect, useState } from "react";

function Cards() {
  // const [videoUrl, setVideoUrl] = useState("");
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    const fetchVideoUrl = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_API_URL);
        const data = response.data;
        // setVideoUrl(data);
        setVideos(data.Videos);
      } catch (error) {
        // console.log("Error fetching video URL:", error);
      }
    };

    fetchVideoUrl();
  }, []);

  // const [videoU, setVideoU] = useState("");
  // const [thumbnailUrl, setThumbnailUrl] = useState("");

  const extractVideoId = (url) => {
    const match = url.match(
      /(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))([^?&]+)/
    );
    return match ? match[1] : null;
  };

  const handleUrlChange = (url) => {
    const videoId = extractVideoId(url);
    // console.log("videoUrl");
    if (videoId) {
      return `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;
    } else {
      return "";
    }
  };
  return (
    <div className="container-fluid">
      <div
        className="fun"
        style={{
          display: "flex",
          flexWrap: "wrap",
          gridColumnGap: "200px",
          gridRowGap: "24px",
          justifyContent: "center",
        }}
      >
        {videos.length !== 0
          ? videos.map((data, index) => {
              const urlPath = handleUrlChange(data.url);
              // console.log("data", urlPath);
              return (
                <div
                  className="card"
                  key={index}
                  style={{
                    width: "20rem",
                    textAlign: "left",
                    border: "none",
                  }}
                  onClick={() => {
                    window.open(`${data.url}`);
                  }}
                >
                  {/* {console.log(data.url, "qwe")} */}
                  {/* <input
                    type="text"
                    value={data.url}
                    // onChange={handleUrlChange}
                    placeholder="Enter YouTube video URL"
                    style={{
                      fontSize: "16px",
                      padding: "10px",
                      display: "none",
                    }}
                  /> */}

                  {urlPath && (
                    <img
                      src={urlPath}
                      alt="YouTube Thumbnail"
                      style={{
                        width: "318px",
                        height: "245px",
                        // borderRadius: "15px",
                      }}
                    />
                  )}
                  {/* <video
                            src={data.url}
                            autoPlay
                            muted
                            loop
                            alt="..."
                            style={{
                              position: "relative",
                              width: "100%",
                              objectFit: "cover",
                            }}
                          /> */}
                  <div className="card-body">
                    <p
                      className="card-text"
                      style={{
                        color: "#000",
                        fontSize: "18px",
                        fontFamily: "Work Sans",
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: "normal",
                      }}
                    >
                      {data.title}
                    </p>
                  </div>
                </div>
              );
            })
          : ""}
      </div>
    </div>
  );
}

export default Cards;
