import React, { useEffect, useState } from "react";
import axios from "axios";

function FooterPage() {
  const [footerSocialMedia, setFooterSocialMedia] = useState([]);
  const [footerSupportedBy, setFooterSupportedBy] = useState([]);
  const [footerContactUs, setFooterContactUs] = useState([]);

  useEffect(() => {
    const fetchFooterData = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_API_URL);
        const data = response.data;
        setFooterSocialMedia(data?.socialMedia || []);
        setFooterSupportedBy(data?.supportedBy || []);
        setFooterContactUs(data?.contactus || []);
      } catch (error) {
        console.log("Error fetching footer data:", error);
      }
    };

    fetchFooterData();
  }, []);

  return (
    <>
      <div className="col my-4" style={{ textAlign: "center" }}>
        {footerSocialMedia.length !== 0 && <h3>Follow us for updates</h3>}

        {footerSocialMedia.length !== 0 &&
          footerSocialMedia.map((data, index) => (
            <div
              key={index}
              style={{
                display: "contents",
                gap: "72px",
                justifyContent: "center",
              }}
            >
              <img
                src={data?.icon}
                alt="Social Media Icon"
                style={{ width: "75px", height: "75px", flexShrink: "0" }}
                onClick={() => {
                  window.open(`${data?.url}`);
                }}
              />
            </div>
          ))}
      </div>

      <div className="col my-4" style={{ textAlign: "center" }}>
        {footerSupportedBy.length !== 0 && <h3>Supported by</h3>}

        {footerSupportedBy.length !== 0 &&
          footerSupportedBy.map((data, index) => (
            <div
              key={index}
              style={{
                display: "contents",
                gap: "72px",
                justifyContent: "center",
              }}
            >
              <img
                src={data?.icon}
                alt="Supported By Icon"
                style={{ width: "75px", height: "75px", flexShrink: "0" }}
              />
            </div>
          ))}
      </div>

      <div className="col my-4" style={{ textAlign: "center" }}>
        {footerContactUs.length !== 0 && <h3>Contact us</h3>}
        <span>{footerContactUs?.phoneno}</span>
        <br />
        <span>{footerContactUs?.email}</span>
      </div>
    </>
  );
}

export default FooterPage;
