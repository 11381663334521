// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `  .video-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 70vh;
    background-color: rgba(0, 0, 0, 0.3);
  }

  .container-fluid.loading {
    background-color: #fff;
  }
  `, "",{"version":3,"sources":["webpack://./src/Components/Dashboard/Styles.css"],"names":[],"mappings":"EAAE;IACE,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,oCAAoC;EACtC;;EAEA;IACE,sBAAsB;EACxB","sourcesContent":["  .video-overlay {\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 70vh;\n    background-color: rgba(0, 0, 0, 0.3);\n  }\n\n  .container-fluid.loading {\n    background-color: #fff;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
