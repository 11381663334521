import React, { useEffect, useState } from "react";
import axios from "axios";
import "./Styles.css";
import Loader from "../Common/Loader";

const Headings = () => {
  const [videoUrl, setVideoUrl] = useState("");
  // const [videos, setVideos] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchVideoUrl = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_API_URL);
        const data = response.data;
        setVideoUrl(data);
        // setVideos(data.Videos);
        setIsLoading(false);
      } catch (error) {
        console.log("Error fetching video URL:", error);
      }
    };

    fetchVideoUrl();
  }, []);

  return (
    <>
      <video
        src={videoUrl.bgVideo}
        autoPlay
        muted
        loop
        playsInline
        type="video/mp4"
        // controls
        className="video"
        style={{
          position: "relative",
          width: "100%",
          height: "70vh",
          objectFit: "cover",
          opacity: 1,
          content: "",
        }}
      />
      <div className="video-overlay"></div>

      <div className={`container-fluid ${isLoading ? "loading" : ""}`}>
        {isLoading ? (
          <div className="loader-overlay">
            <Loader showLoader={isLoading} />
          </div>
        ) : (
          <>
            {/* {isLoading ? <Loader showLoader={isLoading} /> : null} */}

            <div className="video-overlay"></div>

            <div className="container-fluid">
              <div
                className="row"
                style={{
                  position: "relative",
                  display: "grid",
                  textAlign: "center",
                }}
              >
                <div className="col my-5">
                  <strong
                    style={{
                      color: "#000",
                      fontSize: "25px",
                      fontFamily: "Work Sans",
                      fontStyle: "normal",
                      fontWeight: 700,
                      lineHeight: "normal",
                      textAlign: "center",
                    }}
                  >
                    {videoUrl.secondaryTitle}
                    {/* As shown above, Bootstrap generates a series of predefined container classes to help you build the layouts you desire. You may */}
                  </strong>
                  <p
                    style={{
                      textAlign: "justify",
                      marginTop: "26px",
                      color: "#000",
                      fontSize: "20px",
                      fontFamily: "Work Sans",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "normal",
                    }}
                  >
                    {videoUrl.secondaryDescription}
                  </p>
                </div>
                <div style={{ marginBottom: "33px" }}>
                  <strong
                    style={{
                      color: "#000",
                      fontSize: "25px",
                      fontFamily: "Work Sans",
                      fontStyle: "normal",
                      fontWeight: 700,
                      lineHeight: "normal",
                    }}
                  >
                    Check Videos
                  </strong>
                </div>
              </div>
              <div
                style={{
                  position: "absolute",
                  top: "35%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  background: "transparent",
                  textAlign: "center",
                  width: "100%",
                }}
              >
                <h3
                  style={{
                    color: "#FFF",
                    fontSize: "30px",
                    fontFamily: "Work Sans",
                    fontStyle: "normal",
                    fontWeight: 500,
                    lineHeight: "normal",
                  }}
                >
                  {videoUrl.mainTitle}
                  {/* As shown above, Bootstrap generates a series of predefined container classes to help you build the layouts you desire. You may */}
                </h3>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Headings;
