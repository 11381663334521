import "./App.css";
import Dashboard from "./Components/Dashboard/Dashboard";


function App() {
  return (
    <>
      <Dashboard />
    </>
  );
}

export default App;
